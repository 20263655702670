import React from 'react';
import logo from './assets/logo@3x.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <div
          className="App-link"
          style={{ position: 'absolute', top: '10%', right: '10%', transform: 'translate(-50%, -50%)' }}
        >
          Coming Soon
        </div>
      </header>
    </div>
  );
}

export default App;
